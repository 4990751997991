var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "会议基础信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-save-back": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c(
                "el-form",
                {
                  ref: "formLayout",
                  attrs: {
                    model: _vm.dataForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                    disabled: _vm.type == "view",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "组织名称",
                                prop: "belongOrgName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写", disabled: "" },
                                model: {
                                  value: _vm.dataForm.belongOrgName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "belongOrgName", $$v)
                                  },
                                  expression: "dataForm.belongOrgName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "会议议题", prop: "meetingName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.dataForm.meetingName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "meetingName", $$v)
                                  },
                                  expression: "dataForm.meetingName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "会议时间", prop: "meetingDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd HH:mm",
                                  valueFormat: "yyyy-MM-dd HH:mm",
                                  placeholder: "选择日期时间",
                                },
                                model: {
                                  value: _vm.dataForm.meetingDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "meetingDate", $$v)
                                  },
                                  expression: "dataForm.meetingDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会议类型",
                                prop:
                                  _vm.type == "view" || !_vm.typeBoo
                                    ? "meetingTypeName"
                                    : "meetingType",
                              },
                            },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "dictValue",
                                    value: "dictKey",
                                  },
                                  placeholder: "请选择",
                                  dic: _vm.meetingTypeList,
                                },
                                on: { change: _vm.handleTypeChange },
                                model: {
                                  value: _vm.dataForm.meetingType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "meetingType", $$v)
                                  },
                                  expression: "dataForm.meetingType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "安全组织",
                                prop: !this.soOrgBoo ? "soOrgName" : "soOrgId",
                              },
                            },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "soOrgName",
                                    value: "id",
                                  },
                                  placeholder: "请选择",
                                  dic: _vm.soOrgList,
                                },
                                on: { change: _vm.handleSoOrgChange },
                                model: {
                                  value: _vm.dataForm.soOrgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "soOrgId", $$v)
                                  },
                                  expression: "dataForm.soOrgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "参会人数", prop: "userCount" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  min: 0,
                                  precision: 0,
                                  placeholder: "请填写",
                                },
                                model: {
                                  value: _vm.dataForm.userCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "userCount", $$v)
                                  },
                                  expression: "dataForm.userCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "描述", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入描述",
                                  maxlength: "255",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.dataForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "remark", $$v)
                                  },
                                  expression: "dataForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  fileSize: 500,
                  accept: ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  gridRowBtn: _vm.fileGridRowBtn,
                  disabled: _vm.type == "view",
                },
                on: { getTableData: _vm.handleUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "add"
        ? [
            _c(
              "el-container",
              [
                _c(
                  "el-main",
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "参会人员",
                        "head-btn-options": _vm.userBtnOptions,
                      },
                      on: { "head-add": _vm.handleUserAdd },
                    }),
                    _c("grid-head-layout", {
                      ref: "gridHeadLayout",
                      attrs: { "search-columns": _vm.searchColumns },
                      on: {
                        "grid-head-search": _vm.gridHeadSearch,
                        "grid-head-empty": _vm.gridHeadEmpty,
                      },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "grid-row-btn": _vm.gridRowBtn,
                        "table-options": _vm.tableOptions,
                        "table-data": _vm.tableData,
                        "table-loading": _vm.tableLoading,
                        "data-total": _vm.page.total,
                        page: _vm.page,
                        editType: _vm.inline,
                      },
                      on: {
                        "page-current-change": _vm.handleCurrentChange,
                        "page-size-change": _vm.handleSizeChange,
                        "page-refresh-change": _vm.onLoad,
                        "row-remove": _vm.rowUserRemove,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDeptMultipleDialog", {
            ref: "UserDeptMultipleDialog",
            on: {
              "select-all-data": _vm.selectAllData,
              closeDia: function ($event) {
                _vm.deptShow = false
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }